<template>
    <section>
        <!-- img src="@/assets/body/dashboard_1.jpg" alt="">
        <div id="fundo-verde"></div> -->
        <h2>Ready to Scale Your Business?</h2>
        <p>Leverage MADTec’s powerful platforms to optimize your operations, engage users, and unlock new growth opportunities.</p>
        <a href="mailto:admin@madtec.co"><button>Contact Us Today</button></a>
    </section>
</template>

<script>
export default {
    name: 'CTA'
}
</script>

<style scoped>
  section{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    img {
        height: 200px;
        width: 420px;
        border-radius: 20px;
        box-shadow: -10px 15px 10px rgba(0, 0, 0, 0.712);
    }


    #fundo-verde {
        background-color: var(--verde-principal);
        border-radius: 20px;
        height: 200px;
        width: 420px;
        margin-top: -180px;
        margin-right: 30px;
        z-index: -10;
        position: relative;
    }

    h2{
        font-size: 36px;
        font-weight: 900;
        color: var(--preto-principal);
        -webkit-text-stroke: 1px var(--verde-principal);
        text-shadow: 0px 0px 5px var(--verde-principal);
        padding: 60px 40px 10px 40px;
        animation: neon 0.3s alternate infinite;
    }

    @keyframes neon{
        from {
            filter: drop-shadow(0 0 0px var(--verde-principal));
        }
        to {
            filter: drop-shadow(10 20 26px var(--verde-principal));
        }
    }

    p {
        font-size: 16px;
        font-weight: 700;
        padding: 0px 40px 0px 40px;
    }

    #p-ajuste {
        padding-bottom: 100px;
    }

    button {
        border: none;
        width: 300px;
        height: 50px;
        font-size: 20px;
        font-weight: 600;
        background-color: var(--verde-principal);
        margin: 50px 0px;
        cursor: pointer;
    }

    #line {
        width: 80vw;
        border: 1px solid var(--verde-principal);
    }
    

    @media screen and (min-width:1024px) {

        img {
            height: 400px;
            width: 840px;
        }

        #fundo-verde {
            height: 400px;
            width: 840px;
            margin-top: -360px;
            margin-right: 60px;
        }

        h2 {
            font-size: 58px;
            padding: 80px 0px 10px 0px;
        }

        p {
            font-size: 28px;
        }

        #p-ajuste {
            padding-bottom: 200px;
        }

        button {
            width: 600px;
            height: 80px;
            font-size: 40px;
        }
    }
</style>