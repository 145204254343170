<template>
  <header id="header">
    
    <span class="material-symbols-outlined icones-menu" @click='toggleMenu' v-if='!menuAberto'>menu</span>
    <span class="material-symbols-outlined icones-menu" @click='toggleMenu'  v-else>
    close
    </span>
    <nav :class="{open: menuAberto}">
        <li><a href="#about">About Us</a></li>
        <li><a href="#services">Our Services</a></li>
        <li><a href="#why">Why Us</a></li>
        <li><a href="#contact">Contact Us</a></li>
    </nav>
    
    <div id="logo">
        <router-link to="/">
            <img src="@/assets/logo/logo-madtec.png" alt="alt" id="logo">
        </router-link>
            <h2>MADTec</h2>
    </div>
    
    <!--<div id="menu-idioma">
        <p>EN</p>
        <img src="@/assets/menu_idioma/united-states.png" alt="">
        <span class="material-symbols-outlined" id="arrow_donw">keyboard_arrow_down</span>
    </div>    -->

    <!-- <div class="select">
        <div id="category-select">
            <input type="checkbox" name="" id="">
            <div id="select-button">
                <input type="checkbox">
                    <div id="selected-language">EN<img src="@/assets/menu_idioma/united-states.png" alt="">
                    </div>

                <div id="chevrons">
                    <span class="material-symbols-outlined" id="arrow-down">arrow_drop_down</span>
                    <span class="material-symbols-outlined" id="arrow-up">arrow_drop_up</span>
                </div>
            </div>
        </div>
    </div> -->

    <div class="select-language">
        <img src="@/assets/menu_idioma/united-states.png" alt="">
        <select name="" id="" class="custom-select">
            <option value="EN" data-image>EN</option>
            <option value="PT">PT</option>
            <option value="ES">ES</option>
        </select>
    </div>

  </header>
</template>

<script>
import $ from 'jquery';
import 'select2';

export default {
    name: 'Header',

    data(){
        return {
            menuAberto: false,
            selectedOption: null,
            options: [
                {value:'EN', text: 'EN', image: require('@/assets/menu_idioma/united-states.png')}
            ]
            };
        },
    methods: {
        toggleMenu(){
            this.menuAberto = !this.menuAberto;
        }
    },

    mounted(){
        $(this.$refs.select).select2();
    }
}

</script>

<style scoped>
    #header {
        background-color: var(--preto-principal);
        color: var(--verde-principal);
        width: 100vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        z-index: 20;
    }

    #header a{
        text-decoration: none;
        color: inherit;
    }

    /*     #header nav, #header p {
        padding-top: 40px;
    } */




    li {
        list-style: none;
        display: none;
    }

    li:hover{
        color: #D0F086;
    }

    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #logo{
        display: flex;
    }

    #logo img {
        width: 80px;
    }

    #logo h2 {
        display: flex;
        padding-top: 35px;
    }
    /* Select */


    .select-language img {
        position: absolute;
        padding-left: 10px;
        margin-top: 2px;
        height: 25px;
    }

    .custom-select{
        border: 1px solid var(--verde-principal);
        border-radius: 5px;
        background-color: var(--preto-principal);
        color: var(--verde-principal);
        height: 30px;
        width: 80px;
        text-align: end;
    }

    .select{
        border: 1px solid red;
    }

    #select-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--verde-principal);
        border-radius: 10px;
        overflow: hidden;
        height: 50px;
        width: 90px;
    }

    #selected-language{
        display: flex;
        align-items: center;
        padding: 5px;
    }

    #chevrons{
        display: flex;
        align-items: center;
        background-color: var(--verde-principal);
        color: var(--preto-principal);
        height: 50px;
    }

    #arrow-up{
        display: none;
    }

    #selected-language img{
        width: 35px;
        height: 32px;
        padding-left: 5px;
    }

    option img{
        width: 20px;
    }

    /* 
    #menu-idioma{
        border: 3px solid #A6C953;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 90px;
        overflow: hidden;
        cursor: pointer;
        
    }

    #menu-idioma p {
        display: flex;
        padding-left: 5px;
        padding-bottom: 38px;
        padding-top: 40px;
    }

    

    #arrow_donw {
        color: var(--preto-principal);
        background-color: var(--verde-principal);
        display: flex;
        align-items: center;
        height: 50px;
        width: 30px;
    }
*/
@media screen and (max-width: 1024px) {

    
    .icones-menu{
        font-size: 32px;
        margin-bottom: 0px;
        cursor: pointer;
    }

    nav {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        position: absolute;
        top: 8vh;
        left: 0;
        width: 50vw;
        height: 100vh;
        background-color: var(--preto-principal);
        transform: translateX(-100%);
        transition: 0.7s;
        z-index: -100;
    }

    nav.open{
        transform: translateX(0%);
        transition: 0.7s;
    }



    li{
        display: block;
        padding: 30px 0px;
        margin-right: 0;
    }
}
    

    @media screen and (min-width:1024px) {

        .icones-menu{
            display: none;
        }

        nav {
            display: flex;
            flex-direction: row;
        }

        li {
            display: block;
            padding: 0px 10px;
            font-weight: 600;
        }

        #burger-menu {
            display: none;
        }

        #logo {
            order: -1;
        }
    }
    
</style>