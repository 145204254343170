<template>
  <div>
    <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
    
    <Header :logo='logo_src' :alt="site_name" />
    <Top />
    <Map />
    <Services2 />
    <Neon />
    <CTA />
    <Contact />
    <Footer />
    <router-view/>
  </div>
</template>

<script>

import Header from './components/Header.vue';
import Top from './components/Top.vue';
import Slide from './components/Slide.vue';
import Map from './components/Map.vue';
import Neon from './components/Neon.vue';
import Services1 from './components/Services1.vue'
import Services2 from './components/Services2.vue'
import CTA from './components/CTA.vue'
import Contact from './components/Contact.vue'
import FAQ from './components/FAQ.vue'
import Footer from './components/Footer.vue'
import Modal from './components/Modal.vue'

export default {
  components: {
    Header,
    Top,
    Map,
    Neon,
    Services1,
    Services2,
    CTA,
    Contact,
    Footer,
    Modal
  },
  data(){
    return {
      site_name: 'MADTec'
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  
  :root{
    --preto-principal: #121212;
    --verde-principal: #A6C953;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
  }

  body{
    background-color: #121212;
    font-family: 'Poppins';
    color: #A6C953;
    overflow-x: hidden;
    width: 100vw;
  }

  html{
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

</style>
