<template>
    <section>
        <div class="footer">
            <p id="email">email: admin@madtec.co</p>
            <div id="logo">
                <div class="logo"><img src="@/assets/body/MADTec_Preto.png" alt=""><p>MADTec</p></div> 
                <p id="logo-text">GAMIFICATING TECH</p>
            </div>
            <button id="botão">Contact Us</button>
        </div>
        <div class="ajuste-social">
            <img src="@/assets/body/social/linkedin.png" alt="">
        </div>
    </section>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>

    section {
        background-color: var(--verde-principal);
        color: var(--preto-principal);
        text-align: center;
        font-size: 14px;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #line {
        width: 80%;
        border: 1px solid #121212;
    }

    .logo {
        display: flex;
        align-items: center ;
    }

    .logo p{
        padding-top: 10px;
        margin-left: -20px;
        color: black;
        font-size: 48px;
        font-weight: bold;
    }

    .logo img{
        width: 120px;
    }

    #logo-text {
        margin-top: -30px;
        padding-left: 30px;
        padding-bottom: 30px;
        letter-spacing: 7px;
    }

    button {
        border: none;
        margin: 30px 0px;
        width: 250px;
        height: 50px;
        border-radius: 30px;
        background-color: var(--preto-principal);
        color: var(--verde-principal);
        font-size: 22px;
        font-weight: bold;
    }

    .ajuste-social img{
        width: 32px;
        margin: 0px 20px;
        padding-bottom: 50px;
        cursor: pointer;
    }

    #ajuste-p{
        padding: 20px 40px;
    }
    /* Ordem */

    #logo{
        order: 1;
    }

    #email{
        order: 2;
    }

    #botão{
        order: 3;;
    }

    @media screen and (min-width:1024px) {
        .footer {
            flex-direction: row;
            justify-content: space-around;
        }

        #ajuste-p{
            padding-bottom: 50px;
        }

        #logo{
            order: 2;
        }
    }

</style>