<template>
    <section class="top">
        <!-- <div id="degrade"></div> -->
        <div id="background-top">
            <div class="ajuste-video">
            <!-- <video autoplay loop class="video-mobile">
                <source src="/video/video-mobile.mp4" type="video/mp4">
            </video>
             <video autoplay loop class="video-smalldesktop">
                <source src="/video/video-smalldesktop.mp4" type="video/mp4">
            </video> -->
            
                <video autoplay loop muted class="video-desktop">
                    <source src="/video/video-madtec.mp4" type="video/mp4">
                </video>
            </div>
            
            <h2>MADTec</h2>
            <p id="p-mobile">We specialize in cutting-edge data analysis, CRM solutions, and seamless inter-platform management and support. Our innovative approach ensures your data works for you.</p>

            <p id="p-desktop">We specialize in cutting-edge data analysis, CRM solutions,<br> and seamless inter-platform management and support.<br> Our innovative approach ensures your data works for you.</p>
            <a href="#contact"><button>Ready for sucess?</button></a>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Top',
}
</script>

<style scoped>
    * {
        color: #fff;
    }

    .top{
        background: linear-gradient(#121212,#1212128e 80%, #121212);
    }
/*
    .video-mobile {
        z-index: -10;
        position: absolute;
        display: block;
        width: 100vw;
    }

    .video-smalldesktop{
        z-index: -10;
        position: absolute;
        display: none;
        width: 100vw;
    }

    .video-desktop {
        z-index: -10;
        position: absolute;
        display: none;
    }
*/
    .ajuste-video{
        position: absolute;
        z-index: -10;
        max-width: 100vw;
        width: 100vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    h2{
        font-size: 42px;
        text-shadow: 2px 2px 5px black;
    }

    p{
        padding: 0px 60px;
        margin-bottom: 20px;
        text-shadow: 2px 2px 5px black;
    }

    #p-desktop{
        display: none;
    }

    button{
        border: none;
        width: 250px;
        height: 40px;
        color: var(--preto-principal);
        background-color: var(--verde-principal);
        border-radius: 20px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;

    }

    #background-top{
        height: 90vh;
        width: 100vw;
        max-width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    

        @media screen and (min-width:1024px) {
            .top{
                height: 100vh;
            }

            .video-mobile{
                display: none;
            }

            .video-smalldesktop{
                display: block;
            }

            .video-desktop {
                display: block;
            }

            #background-top{
                height: 100vh;
            }

            h2{
                font-size: 96px;
            }

            p {
                font-size: 32px;
                padding: 10px;
            }

            #p-desktop{
                display: block;
            }

            #p-mobile{
                display: none;
            }

            button {
                width: 500px;
                height: 60px;
                border-radius: 40px;
                font-size: 36px;
            }

            #degrade{
                background: linear-gradient(transparent, #121212 80%) ;
                width: 100vw;
                height: 200px;
                position: absolute;
                margin-top: 650px;
            }
        }
    
    @media screen and (min-width:1280px) {
        .video-mobile{
            display: none;
        }

        .video-smalldesktop{
            display: none;
        }

        .video-desktop {
            display: block;
        }

    }
</style>