<template>
    <section id="about">
        <h2>Your partner<br> around the world.</h2>
        <p>With a solid presence across South America and Africa, we are expanding our services to other regions worldwide. Our strong local expertise ensures seamless integration into each market’s unique environment, providing reliable solutions and support for businesses looking to thrive and grow internationally.</p>
        <img src="@/assets/body/map.png" alt="">
    </section>
</template>

<script>
export default {
    name: 'Map',
}
</script>

<style scoped>
    

    section {
        padding: 220px 40px;
        text-align: center;
        background: linear-gradient(#121212, #A6C953, #121212);
        /* background-color: var(--verde-principal); */
        color: var(--preto-principal);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img{
        width: 90vw;
        max-width: 100%;
        padding-top: 50px;
    }

    h2{
        font-size: 42px;
    }

    p{
        font-size: 16px;
        padding-top: 40px;
    }

    @media screen and (min-width:768px) {
        h2 {
            font-size: 64px;
        }
    }

    @media screen and (min-width:1024px) {

        h2{
            font-size: 64px;
        }

        p{
            font-size: 22px;
            width: 80%;
        }

        section {
            padding: 220px 40px;
        }

        img{
            width: 70vw;
            padding-right: 0px;
        }
    }
</style>