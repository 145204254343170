<template>
    <section id="contact">
        <div id="degrade-1"></div>
        <h2>Contact Us</h2>
        <div class="section-cards">
            <div class="card-style">
                <img src="@/assets/body/enterprise.png" alt="">
                <u>
                    <h3>Registered Company</h3>
                </u>
                <p>MENDES ALVES DIAS,<br> UNIPESSOAL LDA</p>
            </div>
            <div class="card-style">
                <img src="@/assets/body/location.png" alt="">
                <u>
                    <h3>Address</h3>
                </u>
                <p>Francisco Luis Silva Street,<br> nº 8, r/ch, 6150-526,<br> Castelo Branco, Portugal</p>
            </div>
            <div class="card-style">
                <img src="@/assets/body/mail.png" alt="">
                <u>
                    <h3>Email</h3>
                </u>
                <p>admin@madtec.co</p>
            </div>
        </div>
        <div id="alinhar-line">
            <div id="line"></div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    section{
        background-color: var(--verde-principal);
        color: #121212;
        text-align: center;
    }

    #degrade-1{
        height: 150px;
        background: linear-gradient(#121212, #A6C953);
    }
    h2{
        font-size: 42px;
    }

    .section-cards{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card-style{
        background: var(--preto-principal);
        color: var(--verde-principal);
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        height: 400px;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card-style img{
        width: 200px;
    }
    .card-style h3{
        padding-top: 20px;
    }

    .card-style p {
        font-size: 20px;
        padding-top: 20px;
    }

    button {
        border: none;
        background-color: var(--preto-principal);
        color: var(--verde-principal);
        width: 70%;
        max-width: 400px;
        height: 60px;
        font-size: 32px;
        margin-top: 50px;
        border-radius: 30px;
        cursor: pointer;
    }
    #alinhar-line {
        display: flex;
        align-items: center;
        justify-content: center;
    }

     #line {
        margin: 100px 0px;
        width: 80%;
        border: 1px solid #121212;
    }


    @media screen and (min-width: 1024px) {

        h2{
            font-size: 64px;
        }

        .section-cards{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .card-style {
            margin: 30px;
        }

        button {
            width: 70%;
            max-width: 600px;
            height: 80px;
            font-size: 48px;
            border-radius: 50px;
        }
    }
</style>