<template>
    <section>
        <div class="services-adjust">
            <div class="services1-img img-left" id="img1"></div>
            <div class="vertical-text vertical-right">
                <div class="vertical-adjust">
                    <p>Data Analysis</p>
                </div>
            </div>
        </div>
        <div class="services-adjust">
            <div class="vertical-text vertical-left">
                <div class="vertical-adjust">
                    <p>Technical Support</p>
                </div>
            </div>
            <div class="services1-img img-right" id="img2"></div>
        </div>
        <div class="services-adjust">
            <div class="services1-img img-left" id="img3"></div>
            <div class="vertical-text vertical-right">
                <div class="vertical-adjust">
                    <p class="font-adjust">Developing Famification</p>
                </div>
            </div>
        </div>
        <div class="services-adjust" id="adjust-padding">
            <div class="vertical-text vertical-left">
                <div class="vertical-adjust">
                    <p class="font-adjust">Sweepstakes Insdustry</p>
                </div>
            </div>
            <div class="services1-img img-right" id="img4"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Services1'
}
</script>

<style scoped>

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        background: linear-gradient(#121212 90%, #A6C953);
    }

    .services-adjust{
        display: flex;
        padding: 50px;
    }

    #img1{
        background-image: url('@/assets/body/2_data_analysis.jpg');
    }

    #img2{
        background-image: url('@/assets/body/3_technical_support.jpg');
    }

    #img3{
        background-image: url('@/assets/body/04-Developing Gamification.jpg');
        background-position: right;
    }

    #img4{
        background-image: url('@/assets/body/05_Sweepstakes Industry.jpg');
        background-position: center;
    }

    .services1-img {
        height: 250px;
        width: 300px;
        background-size: cover;

    }

    .img-right{
        border-radius: 0px 15px 15px 0px;
    }

    .img-left{
        border-radius: 15px 0px 0px 15px;
    }

    /*  Ajuste Texto */

    .vertical-text {
        background-color: var(--verde-principal);
        color: #121212;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        width: 40px;
    }

    .vertical-right {
        box-shadow: inset 7px 0px 10px rgba(0, 0, 0, 0.548);
        border-radius: 0px 15px 15px 0px;
    }
    
    .vertical-left {
        box-shadow: inset -7px 0px 10px rgba(0, 0, 0, 0.548);
        border-radius: 15px 0px 0px 15px;
    }

    p {
        writing-mode: vertical-rl;
    }

    .vertical-adjust {
        transform: rotate(180deg);
    }

    .font-adjust {
        font-size: 18px;
    }

    #adjust-padding {
        padding-bottom: 200px;
    }

    @media screen and (min-width:768px) {
        .services1-img {
            height: 300px;
            width: 500px;
        }

        .font-adjust {
            font-size: 22px;
        }
    }

    @media screen and (min-width:1024px) {
        .services1-img {
            height: 350px;
            width: 800px;
        }

        .font-adjust {
            font-size: 26px;
        }

        .vertical-text {
            font-size: 28px;
        }

    }
</style>