<template>
    <section>
        <div id="div-form">
            <div id="div-close"><span class="material-symbols-outlined">close</span></div>
            <h1>Contact Us</h1>
            <form action="https://formsubmit.co/bruno.hgt2@gmail.com" method="POST">
                <div>
                    <label for="name">
                        <p>Name</p>
                    </label>
                    <input type="text" name="name" id="name" placeholder="Place your name" required>
                </div>
                <div>
                    <label for="email">
                        <p>E-mail</p>
                    </label>
                    <input type="email" name="email" id='email' placeholder="Place your e-mail" required>
                </div>
                <div>
                    <label for="message">
                        <p>Your Message</p>
                    </label>
                    <textarea name="message" id="message" cols="30" rows="10" placeholder="Text your message" required></textarea>
                </div>
                <button type="submit">SUBMIT</button>
                <input type="hidden" name="_subject" value="New Message!">
                <input type="text" name="_honey" style="display:none">
                <input type="hidden" name="_captcha" value="false">
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name:'modal'
}
</script>

<style scoped>

    section {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #12121286;
        width: 100vw;
        height: 100vh;
    }

    #div-form {
        border: 1px solid #A6C953;
        border-radius: 20px ;
        height: 65vh;
        width: 70vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--preto-principal);
    }

    #div-close{
        width: 70vw;
        text-align: end;
    }
    
    span{
        padding-right: 10px;
        cursor: pointer;
    }

    span:hover{
        color: #D0F086;
    }

    h1{
        padding-bottom: 40px;
    }

    label{
        cursor: pointer;
    }

    form{
        display: flex;
        flex-direction: column;
    }

    input, button, textarea{
        height: 30px;
        width: 300px;
        margin-bottom: 20px;
    }

    input{
        padding-left: 10px;
    }

    textarea {
        height: 200px;
        padding: 10px;
    }

    button{
        border: none;
        background-color: var(--verde-principal);
        color: var(--preto-principal);
        font-weight: bolder;
        font-size: 16px;
        cursor: pointer;
    }

    button:hover{
        background-color: #D0F086;
    }

    @media screen and (min-width:1024px){

        #div-form{
            width: 700px;
        }

        #div-close{
            width: 700px;
        }

        input, button, textarea{
        width: 500px;
        margin-bottom: 20px;
    }

    }
</style>