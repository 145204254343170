<template>
    <section id="services">
        <h2>Our Services</h2>
        <div class="alinhamento-div">
            <div class="service-style service-style-1 shadow-style" id="img-user-account">
                <div class="green-background"><h3>User Account Management</h3></div>
                <div id="adjust-text-right">
                    <p>We provide robust User Account Management solutions that ensure seamless onboarding, profile management, and security, tailored to your business needs.</p>
                </div>
            </div>
            <div class= "service-style service-style-2 shadow-style" id="img-sweepstakes">
                <div class="green-background"><h3 id="sweeptakes-tittle">Sweepstakes Systems</h3></div>
                <div id="adjust-text-bottom">
                    <p>Engage and grow your user base with our customizable Sweepstakes Systems, perfect for running promotions that drive excitement and loyalty.</p>
                </div>
            </div>
        </div>
        <div class="alinhamento-div" id="inversão-elementos-div">
            <div class="service-style service-style-1 shadow-style" id="img-affiliate">
                <div class="green-background"><h3>Affiliate Systems</h3></div>
                <div id="adjust-text-left">
                    <p>Maximize your reach and revenue with our advanced Affiliate Systems, designed to track and manage affiliate performance with precision.</p>
                </div>
            </div>
            <div class="service-style service-style-2 shadow-style" id="img-crm">
                <div class="green-background"><h3>CRM Systems</h3></div>
                <div id="adjust-text-bottom">
                    <p>Our Customer Relationship Management tools empower you to streamline user interactions, organize data efficiently, and enhance client retention
                </p>
                </div>
            </div>
        </div>
        <div class="service-style service-style-1 shadow-style" id="img-supplier">
            <div class="green-background"><h3>Supplier Integration</h3></div>
            <div id="adjust-text-supplier">
                <p>We integrate with 30+ leading suppliers in the market, offering solutions for payment methods and entertainment services, ensuring seamless operations across all business channels.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Services2"
}
</script>

<style scoped>
    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--preto-principal);
        padding-top: 50px;
    }

    section h2 {
        color: var(--verde-principal);
        font-size: 36px;
    }

    .green-background {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--verde-principal);
        color: var(--preto-principal);
        text-align: center;
        font-weight: 700;
        height: 30px;
    }

    .alinhamento-div{
        display: flex;
        flex-direction: column;
    }

    .service-style {
        margin: 30px;
        overflow: hidden;
        border-radius: 20px;
    }

    .service-style-1 {
        height: 300px;
        width: 300px;
    }

    .service-style-2 {
        height: 400px;
        width: 300px;
    }

    #img-user-account{
        background: linear-gradient(to right, rgba(0, 0, 0, 0.479) , black 55%) ,url('@/assets/body/dashboard_2.jpg');
        background-size: cover;
        background-position: center center;
    }

    #img-sweepstakes{
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.479) , black 65%) ,url('@/assets/body/service-sweepstakes.jpg');
        background-size: cover;
        background-position: 30% center;
    }

    #img-affiliate{
        background: linear-gradient(to left, rgba(0, 0, 0, 0.479) , black 55%) ,url('@/assets/body/service-affiliate.jpg');
        background-size: cover;
        background-position: center center;
    }

    #img-crm{
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.082) , black 60%) ,url('@/assets/body/service-crm.jpg');
        background-size: cover;
        background-position: 30%;
    }

    #img-supplier{
        background: linear-gradient(to left, rgba(0, 0, 0, 0.425) , rgb(0, 0, 0) 60%) ,url('@/assets/body/service-supplier.jpg');
        background-size: cover;
        background-position: center;
    }

    .shadow-style {
        box-shadow: 0px 0px 30px black;
    }

    #adjust-text-right{
        padding-top: 20px;
        padding-right: 10px;
        width: 50%;
        margin-left: 50%;
        font-size: 14px;
    }

    #adjust-text-bottom {
        margin-top: 250px;
        padding: 10px;
        text-align: center;
        font-size: 14px;
    }

    #adjust-text-left {
        padding-top: 20px;
        width: 50%;
        padding-left: 20px;
        font-size: 14px;
    }

    #adjust-text-supplier {
        padding-top: 10px;
        width: 55%;
        padding-left: 20px;
        font-size: 14px;
    }
    
    @media screen and (min-width:768px) {
        .alinhamento-div{
            flex-direction: row;
        }

        #inversão-elementos-div{
            flex-direction: row-reverse;
        }

        .service-style-1{
            height: 400px;
            width: 350px;
        }

        #img-supplier{
            height: 250px;
            width: 710px;
        }

        #adjust-text-right{
            font-size: 18px;
        }

        #adjust-text-left {
            padding-top: 40px;
            font-size: 18px;
        }

        #adjust-text-bottom{
            margin-top: 225px;
            font-size: 16px;
        }

        #adjust-text-supplier{
            font-size: 18px;
            width: 50%;
            padding-top: 30px;
        }

        #img-supplier{
            background-position: top;
        }
    }

    @media screen and (min-width:1024px) {

        .alinhamento-div{
            flex-direction: row;
        }

        #inversão-elementos-div{
            flex-direction: row-reverse;
        }

         .service-style-1 {
            height: 400px;
            width: 600px;
         }

         h3 {
            font-size: 28px;
         }

         #sweeptakes-tittle{
            font-size: 24px;
         }

        .green-background {
            height: 40px;
        }

         #img-supplier{
            height: 400px;
            width: 970px;
            background-position: top center;
         }

         #adjust-text-right {
            padding-top: 40px;
            width: 40%;
            margin-left: 60%;
            font-size: 20px;
         }

         #adjust-text-left {
            padding-top: 50px;
            width: 45%;
            font-size: 22px;
         }

         #adjust-text-supplier {
            padding-top: 30px;
            width: 30%;
            padding-left: 20px;
            font-size: 22px;
        }   

         #adjust-text-bottom {
            margin-top: 210px;
            font-size: 16px;
         }


    }
</style>